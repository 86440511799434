define("ember-validated-form/passed-or-default", ["exports", "@embroider/macros/es-compat", "@embroider/macros/runtime", "@embroider/util"], function (_exports, _esCompat, _runtime, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = passedOrDefault;
  function passedOrDefault(componentName) {
    return function (target, property) {
      return {
        get() {
          return (0, _util.ensureSafeComponent)(this.args[property] ?? (0, _esCompat.default)(require((0, _runtime.config)("/mnt/c/Users/pgirard/Documents/Work/Projects/TPWD LMA/git/src/MLDP.WebApp/node_modules/ember-validated-form")[componentName])).default, this);
        }
      };
    };
  }
});